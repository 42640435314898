<template>
  <div class="products category-leaflet">
    <Breadcrumb :items="breadcrumbs" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <v-container fluid class="py-0">
      <CategoryTitle
        :category="category"
        :selectedCategory="selectedCategory"
        :count="count"
        :key="category.categoryId"
      />
    </v-container>

    <v-container fluid>
      <div v-if="!categoryId">
        <v-row>
          <v-col
            cols="6"
            sm="4"
            md="3"
            lg="2"
            v-for="subCategory in category.children"
            :key="subCategory.categoryId"
          >
            <v-card
              outlined
              height="100%"
              :to="{
                name: 'Category',
                params: {
                  pathMatch: category.slug
                },
                query: { categoryId: subCategory.categoryId }
              }"
            >
              <v-img
                contain
                :src="
                  $ebsn.meta(
                    subCategory,
                    'category_info.ICON',
                    $ebsn.meta(subCategory, 'category_info.THUMB')
                  )
                "
                :alt="subCategory.name"
                height="110"
                class="center-img mt-3 mx-3"
              ></v-img>
              <v-card-title class="category-block-title">{{
                subCategory.name
              }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <h3 class="mt-5">Tutti i prodotti</h3>
        <ProductListGrid
          class="mt-5"
          :fluid="$vuetify.breakpoint.xsOnly"
          :parentCategoryId="category.categoryId"
          :key="category.categoryId"
          :hidePromoFilter="false"
          hideFilterString="Categorie,Main Term"
          @productsCount="updateCount"
        />
      </div>
      <ProductListGrid
        v-else
        :fluid="$vuetify.breakpoint.xsOnly"
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="category.categoryId"
        :hidePromoFilter="false"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
      />
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.category-leaflet {
  .category-block-title {
    text-align: center;
    justify-content: center;
    word-break: break-word;
  }
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import categoryMixins from "~/mixins/category";
import toNumber from "lodash/toNumber";

export default {
  name: "CategoryLeaflet",
  mixins: [categoryMixins],
  data() {
    return { categoryId: null, count: null };
  },
  components: {
    CategoryTitle,
    ProductListGrid,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  created() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
